import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";

import history from "navigation/history";
import * as analytics from "analytics";
import * as utils from "utils";

import rootReducer from "./rootReducer";
import rootEpic from "./rootEpic";

export const epicDependencies = { history, analytics, utils };

const epicMiddleware = createEpicMiddleware({
  dependencies: epicDependencies
});

export const INIT = "INIT";

export function init() {
  return { type: INIT };
}

export default function configureStore() {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  store.dispatch(init());
  return store;
}
