import { NAVIGATE, REPLACE, BACK, NAVIGATED, NEW_META } from "./events";

export function navigate(route) {
  const uri = (route && route.path) || route;

  return {
    type: NAVIGATE,
    payload: { uri }
  };
}

export function replace(route) {
  const uri = (route && route.path) || route;

  return {
    type: REPLACE,
    payload: { uri }
  };
}

export function goBack() {
  return {
    type: BACK
  };
}

export function navigated(location, type) {
  return {
    type: NAVIGATED,
    payload: { location, type }
  };
}

export function newMeta(title, description, canonicalUrl) {
  return {
    type: NEW_META,
    payload: { title, description, canonicalUrl }
  };
}
