import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import configureStore from "store";
import App from "components/App";
import config from "config";

import "./index.css";

const enabled = config.appEnabled || window.location.search === "?test-enable=true";

render(
  <Provider store={configureStore()}>{enabled ? <App /> : <div />}</Provider>,
  document.getElementById("root")
);
