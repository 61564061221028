import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { navigate, goBack, replace } from "navigation/store/actions";

import styles from "./Link.css";

export class Link extends Component {
  action = e => {
    if (this.props.external) {
      return;
    }
    e.preventDefault();

    if (this.props.type === "back") {
      this.props.goBack();
    } else if (this.props.type === "replace") {
      this.props.replace(this.props.to);
    } else {
      this.props.navigate(this.props.to);
    }
  };

  getHref = () => {
    const { to } = this.props;
    return this.props.type === "back" ? "#" : (to && to.path) || to || "#";
  };

  render() {
    const { children, external } = this.props;

    return (
      <a href={this.getHref()} className={styles.link} onClick={this.action} target={external && "_blank"}>
        {children}
      </a>
    );
  }
}

Link.propTypes = {
  type: PropTypes.oneOf(["navigate", "back", "replace"]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ path: PropTypes.string.isRequired })]),
  children: PropTypes.node.isRequired,
  goBack: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  external: PropTypes.bool
};

const mapDispatchToProps = {
  navigate,
  goBack,
  replace
};

export default connect(
  null,
  mapDispatchToProps
)(Link);
