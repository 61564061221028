import React from "react";
import PropTypes from "prop-types";

import Link from "./Link";
import styles from "./NavItem.css";

function NavItem({ route, name }) {
  return (
    <Link to={route}>
      <span className={styles.text}>{name}</span>
    </Link>
  );
}

NavItem.propTypes = {
  route: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  name: PropTypes.string.isRequired
};

export default NavItem;
