import ReactGA from "react-ga";

import config from "config";

function ifActive(trackingFunction) {
  return config.ga.id ? trackingFunction : function noop() {};
}

export const initialize = ifActive(() => ReactGA.initialize(config.ga.id, config.ga.options));

export const pageview = ifActive(ReactGA.pageview);

export const event = ifActive(ReactGA.event);

export const set = ifActive(ReactGA.set);
