import { combineEpics, ofType } from "redux-observable";
import { tap, map, filter, flatMap, ignoreElements } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { path, pathOr } from "ramda";

import { NAVIGATE, REPLACE, BACK, NAVIGATED } from "./events";
import { navigated, newMeta } from "./actions";
import { getLocation, getQuery } from "./selectors";

export const navigateEpic = (action$, state$, { history, utils }) =>
  action$.pipe(
    ofType(NAVIGATE),
    map(action => action.payload.uri),
    filter(uri => utils.isNewLocation(getLocation(state$.value), uri)),
    tap(uri => history.push(uri)),
    ignoreElements()
  );

export const replaceEpic = (action$, state$, { history, utils }) =>
  action$.pipe(
    ofType(REPLACE),
    map(action => action.payload.uri),
    filter(uri => utils.isNewLocation(getLocation(state$.value), uri)),
    tap(uri => history.replace(uri)),
    ignoreElements()
  );

export const goBackEpic = (action$, state$, { history }) =>
  action$.pipe(
    ofType(BACK),
    tap(() => history.goBack()),
    ignoreElements()
  );

export const browserNavigationEpic = (action$, state$, { history }) =>
  Observable.create(observer => {
    history.listen((location, action) => {
      observer.next(navigated(location, action));
    });
  });

export const seoEpic = (action$, state$, { utils }) =>
  action$.pipe(
    ofType(NAVIGATED),
    flatMap(action => {
      const route = utils.getMatchingRoute(action.payload.location);

      const title = pathOr("404", ["meta", "title"], route);
      const description = pathOr("The page was not found", ["meta", "description"], route);
      let canonicalUrl = pathOr(path(["path"], route), ["meta", "canonicalUrl"], route);

      if (canonicalUrl && typeof canonicalUrl === "function") {
        canonicalUrl = canonicalUrl(getQuery(state$.value));
      }

      utils.writeHead(title, description, canonicalUrl);

      return of(newMeta(title, description, canonicalUrl));
    })
  );

export default combineEpics(browserNavigationEpic, navigateEpic, replaceEpic, goBackEpic, seoEpic);
