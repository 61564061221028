import React from "react";

import RouteSelector from "navigation/components/RouteSelector";
import NavHeader from "./NavHeader";
import routes from "routes";

import styles from "./App.css";

function App() {
  return (
    <div className={styles.container}>
      <NavHeader />
      <main className={styles.main}>
        <RouteSelector routes={routes} />
      </main>
    </div>
  );
}

export default App;
