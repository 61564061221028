import routes from "routes";
import { parsePath } from "navigation/history";

export function isNewLocation(currentLocation, uri) {
  const location = parsePath(uri);

  if (!location.pathname) {
    delete location.pathname;

    if (!location.search) {
      delete location.search;
    }
  }

  return Object.keys(location).filter(key => location[key] !== currentLocation[key]).length > 0;
}

export function normalizePath(path = "/") {
  path = path.endsWith("/") && path !== "/" ? path.slice(0, -1) : path;
  return path.toLowerCase();
}

export function getMatchingRoute(location) {
  const routeName = getMatchingRouteName(location);

  return routeName ? routes[routeName] : null;
}

export function getMatchingRouteName(location) {
  const path = normalizePath(location.pathname);

  return Object.keys(routes).find(routeName => routes[routeName].path === path);
}

export function parseSearch(search = "") {
  search = search.startsWith("?") ? search.substr(1) : search;

  if (!search) {
    return {};
  }

  return search.split("&").reduce((acc, curr) => {
    const [key, val] = curr.split("=");
    const decoded = decodeURIComponent(val);

    if (acc[key] && Array.isArray(acc[key])) {
      acc[key].push(decoded);
    } else if (acc[key]) {
      acc[key] = [acc[key], decoded];
    } else {
      acc[key] = decoded;
    }

    return acc;
  }, {});
}
