import config from "config";

export function writeHead(title, description, canonicalUrl) {
  title = title ? `${title} | NordicStudio.Chuang` : "NordicStudio.Chuang";
  window.document.title = title;

  const head = window.document.head;

  const newTags = [];
  const oldTags = [];

  oldTags.push(head.querySelector("meta[name='description']"));
  oldTags.push(head.querySelector("link[rel='canonical']"));

  if (description) {
    const newTag = document.createElement("meta");
    newTag.setAttribute("name", "description");
    newTag.setAttribute("content", description);
    newTags.push(newTag);
  }

  if (canonicalUrl) {
    let href = config.hostname;
    href += canonicalUrl === "/" ? "" : canonicalUrl;
    const newTag = document.createElement("link");
    newTag.setAttribute("rel", "canonical");
    newTag.setAttribute("href", href);
    newTags.push(newTag);
  }

  oldTags.filter(Boolean).forEach(tag => tag.parentNode.removeChild(tag));
  newTags.filter(Boolean).forEach(tag => head.appendChild(tag));
}
