import React from "react";

import Link from "./Link";
import { HOME } from "routes";
import logo from "assets/images/logo.png";

import styles from "./Logo.css";

function Logo() {
  return (
    <h1 className={styles.title}>
      <Link to={HOME}>
        <div className={styles.container}>
          <img src={logo} className={styles.logo} alt="NordicStudio.Chuang" />
          <span className={styles.text}>NordicStudio.Chuang</span>
        </div>
      </Link>
    </h1>
  );
}

export default Logo;
