import { compose, path } from "ramda";

import { parseSearch } from "utils/location";
import { createPath } from "../history";

const navigation = pathArr => path(["navigation", ...pathArr]);

export const getMatchingRoute = navigation(["matchingRoute"]);
export const getLocation = navigation(["location"]);

export const getUri = compose(
  location => createPath(location || {}),
  getLocation
);

export const getQuery = compose(
  parseSearch,
  navigation(["location", "search"])
);
