import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { event } from "analytics";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false
    };
  }

  componentDidCatch(error) {
    this.setState({
      error: true
    });

    console.error("Render exception:", error);
    event({
      category: "Error",
      action: "Render Exception",
      label: error.message
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h2>Oops, something broke!</h2>
          <p>This was not expected... If it happens again, it is probably really broken.</p>
        </div>
      );
    }

    return <Fragment>{this.props.children}</Fragment>;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
