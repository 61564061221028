import { combineEpics, ofType } from "redux-observable";
import { tap, map, filter, flatMap, switchMap, take, takeUntil, ignoreElements } from "rxjs/operators";
import { of } from "rxjs";
import { path } from "ramda";

import { NAVIGATED, NEW_META } from "navigation/store/events";
import { navigated } from "navigation/store/actions";
import { getLocation, getUri } from "navigation/store/selectors";
import { INIT } from "store";

export const initTrackingEpic = (action$, state$, { analytics }) =>
  action$.pipe(
    ofType(INIT),
    take(1),
    flatMap(() => {
      analytics.initialize();
      return of(navigated(getLocation(state$.value), "PUSH"));
    })
  );

export const pageviewEpic = (action$, state$, { analytics }) =>
  action$.pipe(
    ofType(NAVIGATED),
    filter(action => path(["payload", "type"], action) !== "REPLACE"),
    map(path(["payload", "location"])),
    switchMap(location =>
      action$.pipe(
        ofType(NEW_META),
        take(1),
        tap(() => {
          analytics.set({
            location: window.location.origin + location.pathname + location.search
          });
          analytics.pageview(getUri(state$.value));
        }),
        takeUntil(action$.pipe(ofType(NAVIGATED)))
      )
    ),
    ignoreElements()
  );

export const errorEventEpic = (action$, state$, { analytics }) =>
  action$.pipe(
    filter(action => path(["error"], action) === true),
    tap(action => {
      analytics.event({
        category: "Error",
        action: action.type,
        label: action.payload instanceof Error ? action.payload.message : "Unknown error"
      });
    }),
    ignoreElements()
  );

export default combineEpics(initTrackingEpic, pageviewEpic, errorEventEpic);
