import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { getMatchingRoute } from "navigation/store/selectors";
import NotFound from "components/NotFound";
import ErrorBoundary from "components/ErrorBoundary";

import styles from "./RouteSelector.css";

function childFactoryCreator(classNames) {
  return child =>
    React.cloneElement(child, {
      classNames
    });
}

class RouteSelector extends Component {
  constructor(props) {
    super(props);
    this.lastRoute = props.currentRoute;
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentRoute !== prevProps.currentRoute) {
      this.lastRoute = this.props.currentRoute;
    }
  }

  getTransitionStyles() {
    const lastRoute = (this.lastRoute && this.props.routes[this.lastRoute]) || {};
    const currentRoute = (this.props.currentRoute && this.props.routes[this.props.currentRoute]) || {};

    let prefix = "opacity";
    if (typeof lastRoute.index === "number" && typeof currentRoute.index === "number") {
      prefix = currentRoute.index < lastRoute.index ? "right" : "left";
    }

    return {
      enter: styles[`${prefix}Enter`],
      enterActive: styles[`${prefix}EnterActive`],
      exit: styles[`${prefix}Exit`],
      exitActive: styles[`${prefix}ExitActive`]
    };
  }

  render() {
    const { currentRoute, routes } = this.props;

    const activeStyles = this.getTransitionStyles();

    let RouteComponent = NotFound;
    if (currentRoute && routes[currentRoute]) {
      RouteComponent = routes[currentRoute].Component;
    }

    return (
      <TransitionGroup childFactory={childFactoryCreator(activeStyles)}>
        <CSSTransition key={currentRoute} classNames={activeStyles} timeout={300}>
          <article className={styles.route}>
            <ErrorBoundary>
              <RouteComponent />
            </ErrorBoundary>
          </article>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

RouteSelector.propTypes = {
  currentRoute: PropTypes.string,
  routes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentRoute: getMatchingRoute(state)
});

export default connect(mapStateToProps)(RouteSelector);
