import React from "react";

import routes from "routes";
import Logo from "./Logo";
import NavItem from "./NavItem";

import styles from "./NavHeader.css";

function NavHeader() {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <nav>
        <ul className={styles.nav}>
          <li>
            <NavItem route={routes.HOME} name={"Art"} />
          </li>
          <li>
            <NavItem route={routes.ABOUT} name={"About"} />
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default NavHeader;
