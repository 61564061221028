import { NAVIGATED } from "./events";

import history from "../history";
import { getMatchingRouteName } from "utils/location";

const initialState = {
  location: {
    ...history.location
  },
  matchingRoute: getMatchingRouteName(history.location)
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVIGATED:
      return {
        ...state,
        location: {
          ...action.payload.location
        },
        matchingRoute: getMatchingRouteName(action.payload.location)
      };

    default:
      return state;
  }
};

export default navigationReducer;
