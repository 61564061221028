import ArtPage from "components/ArtPage";
import AboutPage from "components/AboutPage";

export const HOME = {
  index: 0,
  path: "/",
  Component: ArtPage,
  meta: {
    title: "",
    description: "The NordicStudio.Chuang artist web page"
  }
};

export const ABOUT = {
  index: 1,
  path: "/about",
  Component: AboutPage,
  meta: {
    title: "About",
    description:
      "Jenny Chuang Sköld is a creative artist that loves working with ceramics, water colors and acrylic"
  }
};

export default { HOME, ABOUT };
