import React from "react";

import jennyImage from "assets/images/jenny.jpg";

import styles from "./AboutPage.css";

export default function AboutPage() {
  return (
    <div>
      <h2>Jenny Chuang</h2>
      <p>Here we will write some nice words about Jenny and where her inspiration comes from.</p>
      <img src={jennyImage} className={styles.portrait} alt="Jenny Chuang" />
    </div>
  );
}
