import React from "react";

import { HOME } from "routes";
import Link from "./Link";

export default function NotFound() {
  return (
    <div>
      <h2>Not Found</h2>
      <p>This is not the page you are looking for...</p>
      <p>
        Are you perhaps looking for some of the{" "}
        <Link to={HOME}>
          <b>art work</b>
        </Link>
        ?
      </p>
    </div>
  );
}
