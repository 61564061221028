import React from "react";

import Link from "./Link";
import { ReactComponent as InstagramLogo } from "assets/images/instagram.svg";
import styles from "./ArtPage.css";

// TODO: Track, and clean up components etc.
export default function ArtPage() {
  return (
    <div>
      <h2>
        <InstagramLogo className={styles.instagramLogo} /> Instagram Art
      </h2>
      <p>Here we will put some beautiful examples of the art, and where to find more!</p>
      <p>
        Until then, please take a look at the official{" "}
        <Link external to={"https://www.instagram.com/nordicstudio.chuang/"}>
          <b>Instagram</b>
        </Link>
        .
      </p>
    </div>
  );
}
